import React from 'react';
import clsx from 'clsx';

import { getEmbedUrl } from '../../../../../helper';
import * as styles from '../styles.module.css';

const VideoElement = ({ item, subIndex, space, isFirst = true }) => {
  let videoUrl;
  let video = null;
  if (item.videoID) videoUrl = getEmbedUrl(item);
  else if (item?.content?.videoID) videoUrl = getEmbedUrl(item.content);

  if (videoUrl) {
    video = (
      <div
        className={clsx(subIndex == null && `col-10 col-lg-8 ${styles.element}`)}
        style={space ? { marginBottom: `${space}px`, marginTop: `${space}px` } : undefined}
      >
        <iframe
          className={styles.videoIframeStyle}
          src={videoUrl}
          allowFullScreen
          aria-hidden="true"
          loading={isFirst ? 'eager' : 'lazy'}
        />
      </div>
    );
  }

  return video;
};

export default VideoElement;
