// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "L_jQ d_jQ d_cv";
export var galleryMasonryImage = "L_jP d_jP d_w d_bR d_dB";
export var alignLeft = "L_rd d_fp d_bG d_dv";
export var alignCenter = "L_bP d_fq d_bD d_dw";
export var alignRight = "L_rf d_fr d_bH d_dx";
export var galleryContainer = "L_wD d_dW";
export var galleryContainerFull = "L_wF d_dT";
export var galleryRowWrapper = "L_wG d_cc";
export var galleryGuttersImage = "L_jS d_jS d_K d_cv";
export var galleryNoGuttersImage = "L_jR d_jR d_K d_cD";
export var galleryTextGutters = "L_jM d_jM d_cw";
export var galleryTextNoGutters = "L_jN d_jN d_cw";
export var galleryTextMasonry = "L_wH d_jM d_cw";
export var galleryImageWrapper = "L_wJ d_fg d_Z";
export var descText = "L_wK d_jT d_0 d_9 d_7 d_8 d_n";
export var guttersDesc = "L_wL";