// extracted by mini-css-extract-plugin
export var alignLeft = "S_rd d_fp d_bG d_dv";
export var alignCenter = "S_bP d_fq d_bD d_dw";
export var alignRight = "S_rf d_fr d_bH d_dx";
export var testimonialsContainer = "S_xr d_dW";
export var testimonialsContainerFull = "S_xs d_dT";
export var testimonialsMainHeader = "S_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "S_jy d_jy";
export var testimonialsComponentSmall = "S_jx d_jx";
export var testimonialsComponentsWrapper = "S_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "S_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "S_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "S_xt d_d7";
export var colEqualHeight = "S_xv d_bz";
export var testimonialsColumnWrapper = "S_jz d_jz d_b3";
export var testimonialsImageWrapper = "S_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "S_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "S_jr d_jr d_Z d_bz";
export var design2TextWrapper = "S_xw d_bC d_bP d_bJ";
export var design3 = "S_xx d_cD d_H";
export var imageDesign2 = "S_xy";
export var SmallSmall = "S_tM y_tM y_sY y_tk";
export var SmallNormal = "S_tN y_tN y_sY y_tl";
export var SmallLarge = "S_tP y_tP y_sY y_th";
export var exceptionWeight = "S_sK y_tp";