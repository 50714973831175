// extracted by mini-css-extract-plugin
export var tileContent = "Q_w3 d_w d_H d_Z";
export var teamTextLeft = "Q_w4 d_dv";
export var teamTextCenter = "Q_w5 d_dw";
export var teamTextRight = "Q_w6 d_dx";
export var alignLeft = "Q_rd d_w d_bz d_fp d_bG d_dv";
export var alignCenter = "Q_bP d_w d_bz d_fq d_bD d_dw";
export var alignRight = "Q_rf d_w d_bz d_fr d_bH d_dx";
export var teamContainer = "Q_w7 d_dW";
export var teamContainerFull = "Q_w8 d_dT";
export var teamRowWrapper = "Q_w9 d_cc";
export var teamTileWrapper = "Q_j3 d_j3 d_Z d_cv";
export var teamTileSquareWrapper = "Q_xb d_j4 d_Z d_cv";
export var teamTileRoundWrapper = "Q_j4 d_j4 d_Z d_cv";
export var teamTileNoGuttersWrapper = "Q_j5 d_j5 d_Z";
export var teamHoverNoGutters = "Q_j6 d_j6 d_0 d_w d_H d_bz d_bD d_bP d_bm";
export var teamImageText = "Q_kc d_kc d_bl d_w d_by";
export var teamInfoWrapperSquare = "Q_xc d_j7";
export var teamInfoWrapperRound = "Q_j7 d_j7";
export var teamInfoWrapper = "Q_xd d_j8 d_0";
export var teamInfoWrapperNoGutters = "Q_j9 d_j9 d_w d_H d_bD d_bM d_bJ d_c7";
export var teamImgWrapper = "Q_jZ d_jZ";
export var teamImgWrapperAlt = "Q_j0 d_j0";
export var teamImgWrapperNoGutters = "Q_kb d_kb";
export var teamHeader = "Q_xf d_cw";
export var teamHeaderAlt = "Q_xg d_cw";
export var teamHeaderNoGutters = "Q_xh d_cw d_cD";