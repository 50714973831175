// extracted by mini-css-extract-plugin
export var customText = "B_vg d_dv d_cs d_cg";
export var videoIframeStyle = "B_nS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "B_rh d_cs d_cg d_Z";
export var customRow = "B_pb d_bD d_bf";
export var quoteWrapper = "B_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "B_nX d_H";
export var masonryImageWrapper = "B_n3";
export var title = "B_vh";
export var Title3Small = "B_tz y_tz y_sY y_s5";
export var Title3Normal = "B_tB y_tB y_sY y_s6";
export var Title3Large = "B_tC y_tC y_sY y_s7";