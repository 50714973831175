// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "f_mn";
export var warningBackgrounddark = "f_mp";
export var warningBackgroundcustom = "f_mq";
export var bottom = "f_gH";
export var ribbon = "f_mr";
export var modal = "f_ms";
export var bottomRight = "f_mt";
export var btnWrapper = "f_d2 d_bD d_bP d_bJ";
export var contentWrapper = "f_mv";
export var container = "f_mw";
export var titleWrapper = "f_mx d_bD d_bP d_dw";
export var elementWrapper = "f_my d_bD d_bP d_dw";
export var cookieButtons = "f_mz d_bz d_bD d_bP d_bJ";
export var btn = "f_mB d_bz d_bD d_bP";
export var link = "f_mC";
export var decline = "f_mD f_mB d_bz d_bD d_bP";
export var hoverStyle = "f_mF";
export var zoom = "f_mG";
export var row = "f_mH";