// extracted by mini-css-extract-plugin
export var lbContainerOuter = "m_p0";
export var lbContainerInner = "m_p1";
export var movingForwards = "m_p2";
export var movingForwardsOther = "m_p3";
export var movingBackwards = "m_p4";
export var movingBackwardsOther = "m_p5";
export var lbImage = "m_p6";
export var lbOtherImage = "m_p7";
export var prevButton = "m_p8";
export var nextButton = "m_p9";
export var closing = "m_qb";
export var appear = "m_qc";