// extracted by mini-css-extract-plugin
export var alignDiscLeft = "K_wc d_fp d_bG d_dv";
export var alignLeft = "K_rd d_fp d_bG d_dv";
export var alignDiscCenter = "K_wd d_fq d_bD d_dw";
export var alignCenter = "K_bP d_fq d_bD d_dw";
export var alignDiscRight = "K_wf d_fr d_bH d_dx";
export var alignRight = "K_rf d_fr d_bH d_dx";
export var footerContainer = "K_wg d_dW d_bW";
export var footerContainerFull = "K_wh d_dT d_bW";
export var footerHeader = "K_kf d_kf";
export var footerTextWrapper = "K_wj d_w";
export var footerDisclaimerWrapper = "K_km d_km d_ck";
export var badgeWrapper = "K_wk d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "K_wl d_bz d_bJ d_bN d_bL";
export var wide = "K_wm d_cy";
export var right = "K_wn d_bK";
export var line = "K_fk d_fl d_cv";
export var badgeDisclaimer = "K_wp d_bC d_bP d_bJ";
export var badgeContainer = "K_wq d_bz d_bH d_bP";
export var badge = "K_wr";
export var padding = "K_ws d_c7";
export var end = "K_wt d_bH";
export var linkWrapper = "K_wv d_dB";
export var link = "K_mC d_dB";
export var colWrapper = "K_ww d_cx";
export var consent = "K_f d_f d_bC d_bP";
export var disclaimer = "K_wx d_bz d_bJ";
export var media = "K_wy d_bx d_dy";
export var itemRight = "K_wz";
export var itemLeft = "K_wB";
export var itemCenter = "K_wC";
export var exceptionWeight = "K_sK y_tp";