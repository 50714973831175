// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "R_xj d_bD";
export var storyRowWrapper = "R_hx d_hx d_bK";
export var storyLeftWrapper = "R_xk d_bz d_bP";
export var storyWrapperFull = "R_xl d_cD";
export var storyWrapperFullLeft = "R_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "R_mv d_hy";
export var storyLeftWrapperCenter = "R_xm d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "R_xn d_hF";
export var storyHeader = "R_xp d_hD d_w d_cs";
export var storyHeaderCenter = "R_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "R_hB d_hB d_by d_dw";
export var storyBtnWrapper = "R_xq d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "R_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "R_rj d_fg d_Z";
export var imageWrapperFull = "R_vG d_w d_H d_bf d_Z";
export var SubtitleSmall = "R_pd y_pd y_sY y_s8";
export var SubtitleNormal = "R_pf y_pf y_sY y_s9";
export var SubtitleLarge = "R_pg y_pg y_sY y_tb";
export var textLeft = "R_dv";
export var textCenter = "R_dw";
export var textRight = "R_dx";