// extracted by mini-css-extract-plugin
export var carouselContainer = "W_sv d_w d_H d_bf d_Z";
export var carouselContainerCards = "W_x7 W_sv d_w d_H d_bf d_Z";
export var carouselContainerSides = "W_x8 d_w d_H d_Z";
export var prevCarouselItem = "W_x9 d_w d_H d_0 d_k";
export var prevCarouselItemL = "W_yb W_x9 d_w d_H d_0 d_k";
export var moveInFromLeft = "W_yc";
export var prevCarouselItemR = "W_yd W_x9 d_w d_H d_0 d_k";
export var moveInFromRight = "W_yf";
export var selectedCarouselItem = "W_yg d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "W_yh W_yg d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "W_yj W_yg d_w d_H d_Z d_bf";
export var nextCarouselItem = "W_yk d_w d_H d_0 d_k";
export var nextCarouselItemL = "W_yl W_yk d_w d_H d_0 d_k";
export var nextCarouselItemR = "W_ym W_yk d_w d_H d_0 d_k";
export var arrowContainer = "W_yn d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "W_yp W_yn d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "W_yq W_yp W_yn d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "W_yr W_yn d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "W_ys";
export var nextArrowContainerHidden = "W_yt W_yr W_yn d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "W_kG d_0";
export var prevArrow = "W_yv W_kG d_0";
export var nextArrow = "W_yw W_kG d_0";
export var carouselIndicatorContainer = "W_yx d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "W_yy d_w d_bz d_bF";
export var carouselText = "W_yz d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "W_yB W_yz d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "W_yC d_b7";
export var carouselIndicator = "W_yD W_yC d_b7";
export var carouselIndicatorSelected = "W_yF W_yC d_b7";
export var arrowsContainerTopRight = "W_yG d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "W_yH d_0 d_bl d_bC";
export var arrowsContainerSides = "W_yJ d_0 d_bl d_bC";
export var smallArrowsBase = "W_yK d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "W_yL W_yK d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "W_yM W_yL W_yK d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "W_yN W_yK d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "W_yP W_yN W_yK d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "W_n9";
export var iconSmall = "W_yQ";
export var multipleWrapper = "W_yR d_bC d_bF d_bf";
export var multipleImage = "W_yS d_bC";
export var sidesPrevContainer = "W_yT W_yL W_yK d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "W_yV W_yL W_yK d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";