// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "M_wM d_gv d_cs";
export var heroHeaderCenter = "M_gw d_gw d_cs d_dw";
export var heroHeaderRight = "M_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "M_wN d_gr d_cw";
export var heroParagraphCenter = "M_gs d_gs d_cw d_dw";
export var heroParagraphRight = "M_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "M_wP d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "M_wQ d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "M_wR d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "M_wS d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "M_wT d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "M_tR y_tR";
export var heroExceptionNormal = "M_tS y_tS";
export var heroExceptionLarge = "M_tT y_tT";
export var Title1Small = "M_ts y_ts y_sY y_sZ";
export var Title1Normal = "M_tt y_tt y_sY y_s0";
export var Title1Large = "M_tv y_tv y_sY y_s1";
export var BodySmall = "M_tH y_tH y_sY y_tg";
export var BodyNormal = "M_tJ y_tJ y_sY y_th";
export var BodyLarge = "M_tK y_tK y_sY y_tj";