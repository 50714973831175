/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import clsx from 'clsx';

import SectionMedia from '../SectionMedia';

import * as styles from './styles.module.css';

const CustomSectionMedia = ({ variant = 'default', data, style, className, settings, align, ...rest }) => {
  const useWidth = !data.height || (!data.height && !data.width);

  const resolvedSettings = {
    crop: true,
    round: true,
    width: [0, 100, '%'],
    height: [10, 800, 'px'],
    align: true,
    ...settings,
  };
  const resolvedSettingsData = {
    crop: false,
    round: false,
    width: useWidth && resolvedSettings.width ? resolvedSettings.width?.[1] : null,
    height: !useWidth && resolvedSettings.height ? resolvedSettings.height?.[1] : null,
    align: null,
    ...data,
  };

  const imageStyle = useWidth ? null : { maxHeight: resolvedSettingsData.height };

  const classes = [styles.root];
  const wrapperStyle = {};

  if (variant === 'fixedHeight') {
    wrapperStyle.height = `${resolvedSettings.fixedHeight}px`;
  }

  const imageAlign = resolvedSettingsData.align || align;

  if (!data.icon) {
    if (variant === 'default' && resolvedSettingsData.width !== null) {
      wrapperStyle.width = `${resolvedSettingsData.width}${resolvedSettings.width?.[2]}`;
    }
    if (variant === 'default' && resolvedSettingsData.height !== null) {
      wrapperStyle.height = `${resolvedSettingsData.height}${resolvedSettings.height?.[2]}`;
      imageStyle.justifyContent =
        imageAlign === 'Right' ? 'flex-end' : imageAlign === 'Center' ? 'center' : 'flex-start';
    }

    if (['Right', 'Center'].includes(imageAlign)) {
      wrapperStyle.marginLeft = 'auto';
    }
    if (['Left', 'Center'].includes(imageAlign)) {
      wrapperStyle.marginRight = 'auto';
    }

    if (resolvedSettingsData.crop || resolvedSettingsData.round) {
      classes.push(styles.crop);
      if (variant === 'fixedHeight') wrapperStyle.width = `${resolvedSettings.fixedHeight}px`;
      if (resolvedSettingsData.round) classes.push(styles.round);
    }
  }

  return (
    <div style={{ ...style, ...wrapperStyle }} className={clsx(classes, styles[variant], className)}>
      <SectionMedia
        {...rest}
        mediaType={data.icon ? 'ICON' : 'IMAGE'}
        className={styles.wrapper}
        imageClassName={useWidth ? styles.image : styles.imageWithHeight}
        style={imageStyle}
        src={data.CDNLink ? data.CDNLink : `${process.env.IMAGES_CDN}/${data.src}`}
        alt={data.alt}
        data={resolvedSettingsData}
        align={align}
      />
    </div>
  );
};

export default CustomSectionMedia;
