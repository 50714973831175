// extracted by mini-css-extract-plugin
export var alignLeft = "G_rd d_bG";
export var alignCenter = "G_bP d_bD";
export var alignRight = "G_rf d_bH";
export var textAlignLeft = "G_vR";
export var textAlignCenter = "G_vS";
export var textAlignRight = "G_vT";
export var embedInnerWrapperDesign1 = "G_vV d_bM";
export var embed = "G_vW d_b1";
export var titleMargin = "G_vJ d_cw d_dw";
export var subtitleMargin = "G_vX d_cs d_dw";
export var paragraphMargin = "G_vY d_cw d_dw";
export var SubtitleSmall = "G_pd y_pd y_sY y_s8";
export var SubtitleNormal = "G_pf y_pf y_sY y_s9";
export var SubtitleLarge = "G_pg y_pg y_sY y_tb";
export var BodySmall = "G_tH y_tH y_sY y_tg";
export var BodyNormal = "G_tJ y_tJ y_sY y_th";
export var BodyLarge = "G_tK y_tK y_sY y_tj";