import React from 'react';
import HTMLParser from 'html-react-parser';
import clsx from 'clsx';

import CustomSectionMedia from '../../LayoutComponents/CustomSectionMedia';
import SectionMedia from '../../LayoutComponents/SectionMedia';

import * as styles from './styles.module.css';

class DataSheetLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    let columnTitles;
    let columnWidths;
    let columnNum;
    let rowTitles;
    let zebraStripes;
    let lay;
    let align = 'Left';

    if (props.section && props.section.styles) {
      align = props.section.styles.align;
      lay = props.section.styles.layout;
    }

    if (props.section && props.section.data) {
      const dataIndex = props.section.data.findIndex((elem) => elem.type === 'DATA');

      if (dataIndex > -1) {
        ({ columnTitles, columnWidths, columnNum, rowTitles, zebraStripes } = props.section.data[dataIndex]);
      }
    }

    this.state = {
      section: props.section,
      layout: lay,
      align,
      columnTitles,
      columnWidths,
      columnNum,
      rowTitles,
      zebraStripes,
    };

    this.renderHeading = this.renderHeading.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  renderHeading(heading, index) {
    const rowHeadingElement = (
      <thead key={`tableHeading_${index}`}>
        <tr className={styles.tableHeading}>
          {heading.map((head, i) => {
            if ((this.state.rowTitles && i === 0) || (i > 0 && i < this.state.columnNum + 1)) {
              const width = `${this.state.columnWidths[i]}%`;
              const style = { width };

              return (
                <th key={`tableHeading_${i}`} className={`${styles.tableHeadingCell}`} style={style}>
                  <div>
                    <h3 className={`${styles[`Small${this.props.themeData.typography.heading.fontSize}`]}`}>
                      {HTMLParser(`${head.text}`)}
                    </h3>
                  </div>
                </th>
              );
            }

            return null;
          })}
        </tr>
      </thead>
    );

    return rowHeadingElement;
  }

  renderRow(rowData, index, mobileLabels) {
    const color = {
      color: '#000',
    };

    const rowElement = (
      <tr
        key={`tableRow_${index}`}
        className={this.state.zebraStripes ? styles.tableRowStriped : styles.tableRowWrapper}
      >
        {rowData.map((data, i) => {
          const mobileLabelText = mobileLabels && mobileLabels.data[i].text.replace(/<\/?[^>]+(>|$)/g, '');
          const rowWidth = !this.props.matches ? '100%' : `${this.state.columnWidths[i]}%`;
          const rowStyle = { width: rowWidth, clear: !this.props.matches ? 'both' : 'initial' };

          if ((this.state.rowTitles && i === 0) || (i < this.state.columnNum + 1 && i > 0)) {
            let className =
              this.state.rowTitles && i === 0
                ? styles[`Small${this.props.themeData.typography.heading.fontSize}`]
                : undefined;
            const style = this.state.rowTitles && i === 0 ? { ...{ textTransform: 'uppercase' }, ...color } : color;

            let tdClass = this.state.rowTitles && i === 0 ? styles.tableRowHeading : styles.cell;
            if (mobileLabels && mobileLabelText) {
              className = clsx(className, styles.hasLabels);
              tdClass = clsx(tdClass, styles.label);
            }
            return (
              <td
                key={`tableData_${i}`}
                style={rowStyle}
                className={tdClass}
                data-label={
                  this.state.columnTitles && i < this.state.columnNum + 1 && i > 0 ? mobileLabelText : undefined
                }
              >
                <div className={className} style={style}>
                  <div>{HTMLParser(data.text)}</div>
                </div>
              </td>
            );
          }

          return null;
        })}
      </tr>
    );

    return rowElement;
  }

  render() {
    const smallStyle = `Small${this.props.themeData.typography.default.fontSize}`;

    const isFull = this.state.layout === 'fullLeft' || this.state.layout === 'fullRight';

    let { imageWrapper, contentWrapper } = styles;
    let sectionMediaWrapper = '';
    if (isFull === true) {
      imageWrapper = styles.imageWrapperFull;
      sectionMediaWrapper = 'imageContent4';
    }

    const iconStyleName = 'datasheetIcon';
    let leftWrapper = styles.datasheetWrapperFullLeft;
    let rightWrapper = styles.datasheetWrapperFull;
    let colLeft = 'col-12 col-md-6';
    let colRight = 'col-12 col-md-6';
    let rowWrapper = '';

    if (this.state.layout === 'left') {
      rightWrapper = '';
      leftWrapper = styles.datasheetLeftWrapperCenter;
      colLeft = 'col-12';
      colRight = 'col-12';
      rowWrapper = styles.datasheetRowWrapperCenter;
      contentWrapper = styles.contentWrapperCenter;
    } else if (this.state.layout === 'fullRight') rowWrapper = styles.datasheetRowWrapper;

    const leftContent = [];
    const heading = [];
    const rows = [];
    let mobileLabels;
    let image;
    let tableAdded = false;

    this.state.section.data.map((comp, index) => {
      const alignStyle = `align${comp.align || this.state.align}`;
      if (comp.active && comp.type === 'HEADINGS/HEADING-TWO') {
        const result = (
          <div
            key={`heading_${this.state.section._id}_${index}`}
            className={`${styles[alignStyle]} ${styles.titleMargin}`}
          >
            {HTMLParser(`<h2>${comp.text}</h2>`)}
          </div>
        );

        leftContent.push(result);
      } else if (comp.type === 'PARAGRAPH/PARAGRAPH' && comp.active) {
        const result = (
          <div
            key={`subtitle_${this.state.section._id}_${index}`}
            className={`${styles.datasheetSubtitle} ${styles[alignStyle]}`}
          >
            {HTMLParser(`<div>${comp.text}</div>`)}
          </div>
        );

        leftContent.push(result);
      } else if (comp.type === 'PARAGRAPH/SMALL' && comp.active) {
        const result = (
          <div
            key={`subtitle_${this.state.section._id}_${index}`}
            className={`${styles[smallStyle]} ${styles[alignStyle]} ${styles.datasheetFooter}`}
          >
            {HTMLParser(`<div>${comp.text}</div>`)}
          </div>
        );

        leftContent.push(result);
      } else if (comp.active && comp.type === 'IMAGES/IMAGE') {
        if (comp.content.icon) {
          imageWrapper = styles.imageWrapperIcon;
          sectionMediaWrapper = '';
        }

        let sizes;
        if (this.state.layout === 'left') {
          sizes = comp.content.crop || comp.content.round ? '250px' : `${comp.content.width || 100}vw`;
        } else {
          sizes = '(min-width: 768px) 50vw, 100vw';
        }

        image =
          this.state.layout === 'left' ? (
            <CustomSectionMedia
              mediaType={comp.content.icon ? 'ICON' : 'IMAGE'}
              wrapperStyleName={sectionMediaWrapper}
              imageStyleName="datasheetImage"
              iconStyleName={iconStyleName}
              alt={comp.content.alt}
              data={comp.content}
              images={this.props.images}
              colors={this.props.themeData.colors}
              pagePathList={this.props.pagePathList}
              articlePathList={this.props.articlePathList}
              domain={this.props.domain}
              filePathList={this.props.filePathList}
              categoryPathList={this.props.categoryPathList}
              sectionSlugs={this.props.sectionSlugs}
              sizes={sizes}
              loadingStrategy={this.props.isFirst ? 'eager' : undefined}
            />
          ) : (
            <SectionMedia
              key={`image_${this.props.section._id}`}
              mediaType={comp.content.icon ? 'ICON' : 'IMAGE'}
              wrapperStyleName={sectionMediaWrapper}
              imageStyleName="imageFull"
              elementIndex={index}
              iconStyleName={iconStyleName}
              src={comp.content.CDNLink}
              alt={comp.content.alt}
              data={comp.content}
              images={this.props.images}
              pagePathList={this.props.pagePathList}
              articlePathList={this.props.articlePathList}
              domain={this.props.domain}
              filePathList={this.props.filePathList}
              colors={this.props.themeData.colors}
              categoryPathList={this.props.categoryPathList}
              sectionSlugs={this.props.sectionSlugs}
              sizes={sizes}
              loadingStrategy={this.props.isFirst ? 'eager' : undefined}
            />
          );

        if (this.props.layout === 'left') {
          image = (
            <div
              key={`image_${this.props.section._id}`}
              style={comp.content.crop || comp.content.round ? { maxWidth: '250px' } : undefined}
              className={styles.datasheetImageCenterWrapper}
            >
              {image}
            </div>
          );

          if ((comp.content.crop || comp.content.round) && comp.content.align) {
            const justifyContent =
              comp.content.align === 'Left' ? 'flex-start' : comp.content.align === 'Center' ? 'center' : 'flex-end';
            image = (
              <div
                key={`image_${this.props.section._id}_wrapper`}
                style={{ width: '100%', display: 'flex', justifyContent }}
              >
                {image}
              </div>
            );
          }
        }

        if (this.state.layout === 'left') leftContent.splice(0, 0, image);
      } else if (comp.active && comp.type === 'COMPONENT/PERMANENT') {
        heading.push(this.renderHeading(comp.data, index));
        mobileLabels = comp;
      } else if (comp.active && comp.type === 'COMPONENT') {
        rows.push(this.renderRow(comp.data, index, mobileLabels));
      }

      if (
        comp.type.startsWith('COMPONENT') &&
        this.state.section.data[index + 1] &&
        !this.state.section.data[index + 1].type.startsWith('COMPONENT') &&
        !tableAdded
      ) {
        const table = (
          <React.Fragment key={`dataTable_${this.state.section._id}`}>
            <table className={styles.dataTable}>
              {this.state.columnTitles && this.props.matches ? heading : null}
              <tbody>{rows}</tbody>
            </table>
          </React.Fragment>
        );

        tableAdded = true;
        leftContent.push(table);
      }

      return null;
    });

    return (
      <div
        className={isFull && (this.props.full || !this.props.matches) ? undefined : 'container'}
        style={{ width: '100%' }}
      >
        <div className={`row no-gutters ${rowWrapper}`}>
          <div className={`${colRight} ${rightWrapper}`}>
            {this.state.layout !== 'left' && image && <div className={imageWrapper}>{image}</div>}
          </div>
          <div className={`${colLeft} ${leftWrapper}`}>
            <div className={contentWrapper} style={this.props.boxStyle}>
              {leftContent}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DataSheetLayout;
