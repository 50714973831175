// extracted by mini-css-extract-plugin
export var alignLeft = "H_rd d_fp d_bG d_dv";
export var alignCenter = "H_bP d_fq d_bD d_dw";
export var alignRight = "H_rf d_fr d_bH d_dx";
export var FAQMainHeader = "H_kt d_kt d_w d_by";
export var FAQComponentHeader = "H_kz d_kz d_bx";
export var FAQComponentParagraph = "H_kv d_kv d_cD";
export var FAQComponentsWrapper = "H_kr d_kr d_cn d_cL d_cR d_c2 d_cX d_dB";
export var FAQComponentsWrapperClean = "H_ks d_ks d_cn d_cL d_cR d_c0 d_dB";
export var accordion__body = "H_kB d_kB d_by";
export var accordion__bodyHidden = "H_kD d_kD d_bw d_dl";
export var FAQDivider = "H_kq d_kq";
export var accordionWrapper = "H_vZ d_cg";
export var accordion__item = "H_v0 d_cL";
export var accordion_arrow = "H_kH d_kH";
export var accordion_arrow_open = "H_kK d_kK";
export var accordion_arrow_2 = "H_kM d_kM";
export var accordion_arrow_2_open = "H_kP d_kP";
export var Subtitle2Small = "H_tD y_tD y_sY y_tc";
export var Subtitle2Normal = "H_tF y_tF y_sY y_td";
export var Subtitle2Large = "H_tG y_tG y_sY y_tf";