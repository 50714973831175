import React from 'react';
import clsx from 'clsx';

import brokenImage from '../../images/broken.svg';
import getImgExtension from '../../helpers/getImgExtension';

import * as styles from './styles.module.css';
import helper from './helper';

const Image = ({
  className,
  imageStyleName,
  images,
  id,
  url,
  alt,
  style = {},
  transform,
  position,
  opacity,
  size,
  sizes,
  dataIndex,
  onClick,
  containerProps,
  loadingStrategy = 'lazy',
}) => {
  let image = helper.getImageById(id, images);
  let frmt = 'sm';

  if (!image && !id && url) {
    image = {
      path: url,
      alt: alt || 'static',
    };
    frmt = '';
  }

  const imgStyle = {
    ...{ transform: transform ? `rotate(${transform.rotation || 0}deg)` : undefined },
    objectPosition:
      image && !!image.focusX && !!image.focusY ? `${image.focusX * 100}% ${image.focusY * 100}%` : position,
    objectFit: size,
    opacity,
  };

  const isSvgOrGif = ['image/svg+xml', 'image/gif'].includes(image?.mimeType);

  let newExt;
  let newImage = image;
  if (image?.extensions) {
    const idx = image.extensions.findIndex((i) => i !== '.avif');
    if (idx !== -1) {
      newExt = image.extensions[idx];
      newImage = { ...image, path: `${image.path}${image.extensions[idx]}` };
    }
  }

  const srcSet = helper.getSrcSet(newImage);

  if (newImage?.sizes?.includes('og')) frmt = 'og';
  else if (frmt) {
    frmt = newImage?.sizes?.includes('me') ? 'me' : 'sm';
    if (newImage?.sizes && !newImage.sizes.includes(frmt) && newImage.sizes.length > 0) [frmt] = newImage.sizes;

    // Check if browser supports src and sizes W
    if (typeof document !== 'undefined' && document) {
      const img = document.createElement('img');

      if ('sizes' in img) {
        frmt = newImage?.sizes?.includes('th') ? 'th' : 'sm';
        // sizes and srcset is supported so use the lowest quality here for blur
      }
    }
  }

  const imgsrc = helper.getImgSrc(image?.path, frmt, newExt, image?.mimeType);
  const lastSlash = imgsrc.lastIndexOf('/');

  // random alt if not set for better SEO. Actual alt text would be much better though.
  const resolvedAlt =
    newImage?.meta?.imgTitle || newImage?.meta?.imgDesc
      ? newImage?.meta?.imgTitle || newImage?.meta?.imgDesc
      : alt || imgsrc.substring(lastSlash + 1);

  const img = (
    <img
      draggable="false"
      loading={loadingStrategy}
      src={imgsrc || brokenImage}
      srcSet={srcSet || null}
      sizes={srcSet && srcSet.length > 0 ? sizes || '100vw' : undefined}
      alt={resolvedAlt}
      className={clsx(styles.imageElement, className, styles[imageStyleName])}
      data-imageid={id}
      data-imgsrc={imgsrc}
      data-index={dataIndex}
      style={{ ...style, ...imgStyle }}
      width={image?.originalWidth}
      height={image?.originalHeight}
    />
  );

  const ext = image?.filename && !isSvgOrGif ? getImgExtension(image) : null;
  let source;
  if (!isSvgOrGif && ((image?.extensions && image.extensions.length > 1) || ext != null)) {
    const newPath = ext ? image.path.replace(ext, '.avif') : `${image.path}.avif`;
    const sourceImage = { ...image, path: newPath };
    const sourceSet = helper.getSrcSet(sourceImage);

    source = (
      <source
        srcSet={sourceSet || null}
        sizes={sourceSet && sourceSet.length > 0 ? sizes || '100vw' : undefined}
        type="image/avif"
      />
    );
  }

  return (
    <picture
      draggable="false"
      className={clsx(className, styles[imageStyleName])}
      onClick={onClick}
      data-imageid={id}
      data-imgsrc={imgsrc}
      data-index={dataIndex}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...containerProps}
    >
      {source}
      {img}
    </picture>
  );
};

export default Image;
