// extracted by mini-css-extract-plugin
export var noMargin = "v_ss d_cc";
export var carouselCol = "v_st d_cD";
export var carouselWrapper = "v_k6 d_k6 d_bz d_bD d_bP d_w d_H";
export var carouselImageWrapper = "v_k7 d_k7 d_H d_w";
export var carouselContainer = "v_sv d_cD";
export var carouselContainerFull = "v_sw d_dT";
export var carouselImg = "v_mR d_k8 d_w d_H d_bR";
export var imageTextWrapperLeft = "v_k1 d_k1 d_k0 d_0 d_bT";
export var imageTextWrapperCenter = "v_k2 d_k2 d_k0 d_0 d_bT";
export var imageTextWrapperRight = "v_k3 d_k3 d_k0 d_0 d_bT";
export var imageTextWrapperHidden = "v_kZ d_kZ";
export var imageAltSmall = "v_sx d_k4";
export var imageAltNormal = "v_sy d_k4";
export var imageAltLarge = "v_sz d_k4";
export var imageTextSmall = "v_sB d_k5";
export var imageTextNormal = "v_sC d_k5";
export var imageTextLarge = "v_sD d_k5";