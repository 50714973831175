// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "C_vj d_bD";
export var datasheetRowWrapper = "C_lt d_lt d_bK";
export var datasheetLeftWrapper = "C_vk d_bz d_bP";
export var datasheetWrapperFull = "C_vl d_cD";
export var datasheetWrapperFullLeft = "C_qT d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "C_mv d_lv";
export var contentWrapperCenter = "C_vm d_lz";
export var datasheetLeftWrapperCenter = "C_vn d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "C_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "C_vp d_lw";
export var datasheetFooter = "C_ly d_ly";
export var alignLeft = "C_rd d_dv";
export var alignCenter = "C_bP d_dw";
export var alignRight = "C_rf d_dx";
export var datasheetInnerWrapperNewLeft = "C_vq d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "C_vr d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "C_vs d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "C_vt d_bK";
export var datasheetSubtitle = "C_vv d_cx";
export var tableRow = "C_vw";
export var cell = "C_vx";
export var tableRowWrapper = "C_vy d_w d_dv";
export var tableHeadingCell = "C_vz";
export var tableHeading = "C_vB";
export var tableRowStriped = "C_vC";
export var tableRowHeading = "C_vD";
export var dataTable = "C_vF d_w";
export var imageWrapper = "C_rj d_fg d_Z";
export var imageWrapperFull = "C_vG d_H d_w d_bf d_Z";
export var imageWrapperIcon = "C_vH";
export var titleMargin = "C_vJ d_cs";
export var datasheetInnerInnerWrapper = "C_vK d_w";
export var hasLabels = "C_vL";
export var label = "C_vM";
export var SmallSmall = "C_tM y_tM y_sY y_tk";
export var SmallNormal = "C_tN y_tN y_sY y_tl";
export var SmallLarge = "C_tP y_tP y_sY y_th";