// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "V_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "V_fR d_fR d_bz d_bJ";
export var menuDesign6 = "V_xB d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "V_xC d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "V_xD d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "V_xF d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "V_xG d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "V_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "V_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "V_xH";
export var navbarItem = "V_nb d_bx";
export var navbarLogoItemWrapper = "V_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "V_xJ d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "V_xK d_gd d_by d_Z d_bs";
export var burgerToggle = "V_xL d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "V_xM d_gd d_by d_Z d_bs";
export var burgerInput = "V_xN d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "V_xP d_f3 d_w d_H";
export var burgerLine = "V_xQ d_f1";
export var burgerMenuDesign6 = "V_xR d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "V_xS d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "V_xT d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "V_xV d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "V_xW d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "V_xX d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "V_xY d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "V_xZ d_bC d_bP";
export var compact = "V_x0";
export var navDivided = "V_x1";
export var staticBurger = "V_x2";
export var menu = "V_x3";
export var navbarDividedLogo = "V_x4";
export var nav = "V_x5";
export var fixed = "V_x6";